import { md5 } from './compression/md5'

/* 使用encryptlong加密方法，在自己项目中从后端获取的publicKey存入localStorage，encrypt会从本地获取密钥进行加密 */

export default function encrypt(data) {
  // 定义时间戳
  const timestamp = new Date().getTime()

  let params = []

  // 判断是否有传参 有则进行加密
  params = params.concat(handleParams(cleanObject(data)))
  // 去重复项
  params = [...new Set(params)]
  // 根据首字母排序
  params = params.sort((a, b) => {
    return (`${a}`).localeCompare(`${b}`)
  })
  // 拼装成字符串
  let _params = params.join("&");
  console.log(_params)
  _params = `${params.length === 0 ? '' : `${md5(_params)}&`}timestamp=${timestamp}`

  return RASEncrypt().encrypt(_params,'base64')

}

const RASEncrypt = () => {
  const _public =
    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAgi8r1CCl1V419T8wkFFYlcuuro4s1hDuA/K+jPsbVDzLqqlTZ9qqlwDPLxfJEu6k0T1mG3nEiUuh6vwkw6kkvpsmNn4b6/GR4X1GxQ88xBhnEvLS4UvbB12LbPCgt7sTR+FnsHrsq6v9FTMAzYfWanb6ahjNZSY1CnaXu4Y5pZEKO8wMdpX0jN4L35NzH4E931VBvgiHbz+HonT0IpEnIudNLj0LkpGF+LHiUSZaoW7Zj/tqpNmgASvlSulAuHcC//0v0GBUWSR5B/WeXRN5cScrDrkNeYhl6OgH3oTPastKSrnviYfsL4nhQrKZXLZMtcAaZW7+95JsfhgK9hZtnQIDAQAB"
  const NodeRSA = require('node-rsa')
  const rsa = new NodeRSA(_public,'public');
  rsa.setOptions({encryptionScheme: 'pkcs1'});
  return rsa
};

// 解析传参对象
const handleParams = params => {
  if (Object.keys(params) && Object.keys(params).length !== 0) {
    const res = [];
    for (const key in params) {
      if (typeof params[key] === 'object') {
        res.push(`${key.toUpperCase()}=${JSON.stringify(params[key])}` || '')
      } else {
        res.push(`${key.toUpperCase()}=${params[key]}` || '')
      }
    }
    return res;
  } else {
    return [];
  }
};
function cleanObject(obj) {
  // 检查是否是数组
  if (Array.isArray(obj)) {
      return obj.map(item => cleanObject(item));
  }
  // 检查是否是对象
  if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === undefined) {
          delete obj[key];
      } else {
          obj[key] = cleanObject(obj[key]);
      }
    });
  }
  return obj;
}
