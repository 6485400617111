import axios, { type AxiosInstance, type AxiosRequestConfig } from "axios"
import { get } from "lodash-es"
import { getToken,removeToken} from "@/lib/cookies";
import Base64 from './js-base64'
import {siteConfig} from '@/config/site'
import encrypt from './encrypt'
const baseUtil = new Base64()

// // 创建一个 CancelToken.source
// const cancelTokenSources = new Map();

/** 创建请求实例 */
function createService() {
    // 创建一个 Axios 实例
    const service = axios.create()
    // 请求拦截
    service.interceptors.request.use(
        (config) => {
          if(config.url === '/api-user/wx/miniapp/front/v1.0/loginQRCode?tenantId=iReport-front'){
            config.responseType= "blob"
          }

        //   // 生成请求标识，可以根据具体需求生成
        //   const requestKey = `${config.method}_${config.url && config.url.split('?')[0]}`;
        //   // 如果当前请求已经在进行中，取消之前的请求
        //   if (cancelTokenSources.has(requestKey)) {
        //     cancelTokenSources.get(requestKey).cancel('Request canceled due to duplicate request');
        //   }

        //   // 创建新的 CancelToken 实例，并将其与请求配置关联
        //   const source = axios.CancelToken.source();
        //   config.cancelToken = source.token;

        //   // 将新的 CancelToken 存入 Map
        //   cancelTokenSources.set(requestKey, source);


         return config
        },
        // 发送失败
        (error) => Promise.reject(error)
    )
    // 响应拦截（可根据具体业务作出相应的调整）
    service.interceptors.response.use(
        (response) => {
            // // 请求成功时，从 Map 中移除对应的 CancelToken
            // const requestKey = `${response.config.method}_${response.config.url}_${JSON.stringify(response.config.params)}`;
            // cancelTokenSources.delete(requestKey);

            // apiData 是 API 返回的数据
            const apiData= response.data as any
            const headers = response.headers
            if(headers['logincode']){
             apiData.logincode = headers['logincode']
            }
            // 这个 Code 是和后端约定的业务 Code
            const code = apiData.resp_code
            // 如果没有 Code, 代表这不是项目后端开发的 API
            if (response.headers["Content-Type"] === "application/json" && code === undefined) {
                return Promise.reject(new Error("非本系统的接口"))
            } else {
                switch (code) {
                    case 0:
                        // code === 0 代表没有错误
                        return apiData
                    case 401:
                      removeToken()
                      break
                      case 402:
                        removeToken()
                        break
                    case 1001:
                        window.location.reload()
                      removeToken()
                      break
                    case 1002:
                      removeToken()
                        //登陆过期
                        return
                    default:
                        // 不是正确的 Code
                        return apiData
                }
            }
        },
        (error) => {
            // Status 是 HTTP 状态码
            const status = get(error, "response.status")
            switch (status) {
                case 400:
                    error.message = "请求错误"
                    break
                case 401:
                    // Token 过期时，直接退出登录并强制刷新页面（会重定向到登录页）
                    removeToken()
                    break
                case 403:
                    error.message = "拒绝访问"
                    break
                case 404:
                    error.message = "请求地址出错"
                    break
                case 408:
                    error.message = "请求超时"
                    break
                case 500:
                    error.message = "服务器内部错误"
                    break
                case 501:
                    error.message = "服务未实现"
                    break
                case 502:
                    error.message = "网关错误"
                    break
                case 503:
                    error.message = "服务不可用"
                    break
                case 504:
                    error.message = "网关超时"
                    break
                case 505:
                    error.message = "HTTP 版本不受支持"
                    break
                default:
                    break
            }
            return Promise.reject(error)
        }
    )
    return service
}
function getHeaders(config: any, params: any, token?: any, defaultConfig?: any): any {
    const commonHeaders = {
      tenant: siteConfig.clientId,
      sign: encrypt(params.data ? params.data : params.params ? params.params : ""),
      "Content-Type": get(config, "headers.Content-Type", "application/json"),
    };

    if (config.url === '/api-uaa/oauth/remove/token') {
      // 不携带 Token 的情况
      return { ...commonHeaders };
    } else {
      // 携带 Token 的情况
      return {
        ...commonHeaders,
        Authorization: token ? "Bearer " + token : 'Basic ' + baseUtil.encode(defaultConfig),
      };
    }
  }
/** 创建请求方法 */
function createRequestFunction(service: AxiosInstance) {
    return function <T>(config: AxiosRequestConfig, params: any): Promise<T> {
        const token = getToken();
        const defaultConfig = `${siteConfig.clientId}:${siteConfig.clientSecret}`
        const configDefault = {
            headers: getHeaders(config, params, token, defaultConfig),
            timeout: 50000,
            baseURL: process.env.NEXT_PUBLIC_SERVER,
            ...params,
          };

        // 传递 ip 地址
        process.env.CONFIG === 'canary' && (configDefault.headers['canary-ip'] = window.localStorage.getItem('myIP'))
        return service(Object.assign(configDefault, config))
    }
}


/** 用于网络请求的实例 */
export const service = createService()
/** 用于网络请求的方法 */
export const request = createRequestFunction(service)
